<template>
  <!--Breadcrumbs -->
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  
  <div id="maincontent_container">
    
    

    <!-- Right Side: main content -->
    <div class="float-end col-md-10 ps-1 mb-5">
      <iframe id="idIframe" :src="file_path" @load="iframeLoaded"
       width="100%" title="Iframe Example"
      frameborder="1">
      
      </iframe>
    </div>
    <!-- End of Right Side: main content -->

    <!-- Left Side: category list, brand list and ads -->
    <div class="float-start col-md-2 pe-5 mb-5" id="category_list">
      <submenuMicrosite
            :brand="brand"
          />

    </div>
    <!-- End of Left Side: category list and brand list -->

  </div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import submenuMicrosite from "../../components/nav/submenuMicrosite.vue";
import { StaticPath } from '../../utils/settings'
import { getAPI } from '../../utils/axios-api'
import { ref } from "vue";

export default {
  name: 'Microsite',
  components: { submenuMicrosite, BreadcrumbPage },
  props: [ "brand" ], 
  setup(props) {
    const brand = ref(props.brand);
    const file_path = StaticPath + "/landing_page/" + props.brand + "/index.html"

    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Partners", link: ""})
    breadcrumb_links.value.push({text: "Featured Brands", link: "Partners"})
    breadcrumb_links.value.push({text: "Microsite", link: "FeaturedStores"})

    //get brand name and show it in breadcrumb
    getAPI
    .get("/product/manufacturer/" + props.brand + "/")
    .then((response) => {
      let data = response.data
      let brand_name = data.results[0].brand_name
      breadcrumb_links.value.push({text: brand_name, link: ""})
    })

    //dynamically set the iframe height by the height of the content inside
    const iframeLoaded = () => {
      var iFrameID = document.getElementById('idIframe');
      if(iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            let height = iFrameID.contentWindow.document.body.scrollHeight + 2000;
            iFrameID.height = height.toString() + "px";
      }   
    }

    return { brand,
             file_path, 
             iframeLoaded,
             breadcrumb_links,
          }
  }
}
</script>

<style>
  #category_list {
    font-size: 12px;
  }
</style>