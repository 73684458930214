<template>
<div v-if="adVerticalDisplay">
    <div v-for="item in adVerticalDisplay" :key="item">
        <router-link :to="item[1]"><img :src="item[0]" :height="380" :width="300" class="mt-4 response"></router-link>
    </div>

</div>

    
</template>

<script>
import { ref } from 'vue'
import { getAPI } from '../../utils/axios-api';
import moment from "moment";
export default ({
    setup() {
        const message = ref("")
        const adVertical = ref([]);
        const adVerticalDisplay = ref([]);
        const ads = ref([])
        const current = new Date();
        const today = moment(current).format("YYYY-MM-DD");

        let url = "content/snippets/adbanner/productlist/"
        getAPI.get(url).then((response) => {
            ads.value = response.data.items
            for(let index in ads.value){
                if (
                    today >= ads.value[index].start_date &&
                    today < ads.value[index].end_date
                ){
                    let link = ads.value[index].link
                    adVertical.value.push([ads.value[index].image.meta.download_url,link])
                }                
            }
            adVerticalDisplay.value = adVertical.value.sort(function() { return .5 - Math.random() }).slice(0, 2);
            // console.log(adVerticalDisplay)
            
        })
        .catch((err) => {
            message.value = "No image!"
        });
        return {adVerticalDisplay};
        
    },
})
</script>

<style scoped>
.response {width: 100%;height: auto;}
</style>
