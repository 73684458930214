<template>
  <div class="mb-5">
    <h5 class="accordion bar">{{brand_name}} Category</h5>
    <ul class="list-group ps-2">
      <li v-for="item in subcate" class="list-group-item"><router-link :to="'/products/?catid=' + item.catid + '&mfid=' + mfid">{{item.categoryname}}</router-link></li>
      <!-- show additional category list  -->
      <li v-for="item in additional_category_list" class="list-group-item"><a :href="item.category_url">{{item.category_name}}</a></li>
    </ul>
    
  </div>

  <!-- <div class="mt-5 mb-5">
    <h5 class="">Brand</h5>
    <ul class="list-group">
      <li v-for="item in brand_list" class="list-group-item"><a :href="'/products/?page=1' +'&mfid=' + item.mfid">{{item.brand_name}}</a></li>
    </ul>
  </div> -->

  <!-- <ads-vertical></ads-vertical> -->
</template>


<script>
import AdsVertical from "../ads/AdsVertical.vue";
import { ref, toRefs, watch } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  components: { AdsVertical },
  props: ['brand'],
  setup(props, context) {
    const subcate = ref(null)
    const brand_list = ref(null)
    const brand_name = ref(null)
    const mfid = ref(null)
    var additional_category_list = ref([])

    //get brand list
    getAPI
    .get("/product/manufacturer/" + props.brand)
    .then(response => {
        brand_list.value = response.data.results
        brand_name.value = brand_list.value[0].brand_name
        mfid.value = brand_list.value[0].mfid

        //set customized category list
        var microsite_addi = brand_list.value[0].microsite_additional
        let tmp = null
        let item = {}
        if (microsite_addi) {
          var rows = brand_list.value[0].microsite_additional.split("\n")
          console.error(rows.length)
          for (let i = 0; i < rows.length; i++){
            if (rows[i] != "") {
              tmp = rows[i].split(": ")
              if (tmp.length == 2) {
                additional_category_list.value.push({"category_name": tmp[0], "category_url": tmp[1]})
              }
            }
          }
        }

        //get subcategory list
        getAPI
        .get("/product/category/?mfid=" + brand_list.value[0].mfid)
        .then(response => {
          subcate.value = response.data["results"]
        })
        .catch(err => {
        //pass
        })
      
    })
    .catch(err => {
        //pass
    })
    
   
    return {
      subcate,
      brand_list,
      brand_name,
      mfid,
      additional_category_list
    }
  }
}
</script>

<style scoped>
a {
  color: black;
  text-decoration: none;
}
li {
  border:0px solid #fff !important;
  line-height: 15px;
  padding-left:0 ;
}
.bar {
  padding:10px;
  background-color:#eee;
}
</style>